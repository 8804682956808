import React from "react";
import { Page, Navbar, Block } from "framework7-react";
import WeekReportPlot from "../Components/WeekReportPlot"
import firebase from "firebase";

export interface WeekReportProps {
    start:Date;
    end:Date;
}
class WeekReport extends React.Component{
    db: firebase.firestore.Firestore;
    start: Date;
    end: Date;

    constructor(props:WeekReportProps){
        super(props);
        this.db = firebase.firestore();
        this.start = props.start;
        this.end = props.end;

    }

    render(){
        return <Page name="Week Report" >

            <Navbar title="Week Report" backLink={true} />

            <Block>
                <WeekReportPlot db={this.db} start={this.start} end={this.end} userId={firebase.auth().currentUser.email}></WeekReportPlot>
                
            </Block>


        </Page>
    }

}

export default WeekReport;