import React, { StyleHTMLAttributes, CSSProperties } from 'react';
import { Page, Navbar, Block, Range, Swiper, SwiperSlide, Row, Col, Button, BlockTitle, BlockHeader, TextEditor, F7TextEditor, List, ListInput } from 'framework7-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeh, faSmile, faFrown, faCalendarCheck, faSadTear, faSmileBeam, IconDefinition } from '@fortawesome/free-regular-svg-icons'
import 'font-awesome/css/font-awesome.css';
import firebase from 'firebase';
import DayPlot from '../Components/DayPlot';
import { format  } from 'date-fns'

var Android: any;

interface State {
    moodIcon?: IconDefinition;
    moodStyle?: CSSProperties;
    moodValue?: number;
    notes?: string;
    achievements?: string;
    toDoBetter?: string;
    gratefulFor1?: string;
    gratefulFor2?: string;
    gratefulFor3?: string;
}

interface DayReviewData {
    moodValue: number;
    notes: string;
    achievements: string;
    toDoBetter: string;
    gratefulFor1?: string;
    gratefulFor2?: string;
    gratefulFor3?: string;
}

export interface DailyReviewProps {
    day: Date;
}

class DailyReview extends React.Component<DailyReviewProps, State> {

    state: State;
    db: firebase.firestore.Firestore;

    userId: string = firebase.auth().currentUser.email;
    dateString: string;
    date: Date;




    constructor(props: DailyReviewProps) {
        super(props);
        this.db = firebase.firestore();
        
        this.date = new Date(props.day.getFullYear(), props.day.getMonth(), props.day.getDate(), 12, 0, 0);
        this.dateString = format(this.date, "yyyy-MM-dd");


        this.state = {
            moodValue: 50,
            notes: '',
            achievements: '',
            toDoBetter: '',
            gratefulFor1: '',
            gratefulFor2: '',
            gratefulFor3: '',
            moodIcon: faMeh,
            moodStyle: { color: 'black' }
        }

        this.db.collection('user').doc(this.userId).collection("dayReview").doc(this.dateString).get().then(dayReview => {
            let data: DayReviewData = dayReview.data() as DayReviewData;


            if (data) {
                this.setState((prevState, props) => {
                    let newState: State = {
                        moodValue: data.moodValue ? data.moodValue : prevState.moodValue,
                        notes: data.notes ? data.notes : prevState.notes,
                        achievements: data.achievements ? data.achievements : prevState.achievements,
                        toDoBetter: data.toDoBetter ? data.toDoBetter : prevState.toDoBetter,
                        gratefulFor1: data.gratefulFor1 ? data.gratefulFor1 : prevState.gratefulFor1,
                        gratefulFor2: data.gratefulFor2 ? data.gratefulFor2 : prevState.gratefulFor2,
                        gratefulFor3: data.gratefulFor3 ? data.gratefulFor3 : prevState.gratefulFor3

                    }
                    return newState;
                })


            }


        });



    }

    sliderderStyle = {
        height: '100%'
    }
    onMoodChange(value: number) {
        let newState: State = {
            moodValue: value,
            moodIcon: this.getMoodIcon(value),
        };
        this.setState(newState);

    }

    private getMoodIcon(value: number): IconDefinition {
        let moodIcon = faMeh;
        if (value > 80) {
            moodIcon = faSmileBeam;
        } else if (value > 60) {
            moodIcon = faSmile;
        } else if (value > 40) {
            moodIcon = faMeh;
        } else if (value > 20) {
            moodIcon = faFrown;
        } else {
            moodIcon = faSadTear;
        }

        return moodIcon;
    }

    onMoodChanged(value: number) {
        let time = firebase.firestore.Timestamp.fromDate(this.date)
        let result = time.toDate();
        let myDate = result.toISOString();
        this.saveChangesToFirebase();
    }

    render() {
        return (
            <Page name="Daily Review" >
                <Navbar title="Daily Review" backLink={true} />

                <Swiper pagination
                    navigation
                    params={{
                        pagination: {
                            clickable: true,
                        },
                        on: {
                            slideChange: this.slideChanged.bind(this)
                        }
                    }}


                    className="swiper-no-swiping"
                    style={this.sliderderStyle}

                >
                    <SwiperSlide >
                        <Block >
                            <p>Let's review the day.</p>
                            <p>How Was Today</p>
                        </Block>

                        <Block>

                            <Row >
                                <Col width="100" className="center-col">
                                    <FontAwesomeIcon icon={this.state.moodIcon} style={this.state.moodStyle} size="5x" />
                                </Col>

                            </Row>
                            <br></br>
                            <br></br>



                            <Row>
                                <Range
                                    min={0}
                                    max={100}
                                    step={5}
                                    value={this.state.moodValue}
                                    onRangeChange={this.onMoodChange.bind(this)}
                                    onRangeChanged={this.onMoodChanged.bind(this)}
                                />
                            </Row>

                        </Block>

                    </SwiperSlide>

                    <SwiperSlide  >

                        <BlockTitle>Notes</BlockTitle>
                        <BlockHeader>Write something about your day.</BlockHeader>
                        <TextEditor
                            placeholder="Today ..."
                            mode="popover"
                            value={this.state.notes}
                            buttons={['bold', 'italic', 'underline', 'strikeThrough']}
                            style={{ height: "150px" }}
                            onTextEditorChange={this.onNotesChange.bind(this)}

                        />
                    </SwiperSlide>

                    <SwiperSlide  >


                        <BlockHeader>Something I achieved or learned today:</BlockHeader>
                        <TextEditor
                            placeholder="Today ..."
                            mode="popover"
                            value={this.state.achievements}
                            buttons={['bold', 'italic', 'underline', 'strikeThrough']}
                            style={{ height: "80px" }}
                            onTextEditorChange={this.onAchivementsChange.bind(this)}

                        />

                        <BlockHeader>Something I could do differently:</BlockHeader>
                        <TextEditor
                            placeholder="Today ..."
                            mode="popover"
                            value={this.state.toDoBetter}
                            buttons={['bold', 'italic', 'underline', 'strikeThrough']}
                            style={{ height: "80px" }}
                            onTextEditorChange={this.onToDoBetterChange.bind(this)}

                        />
                    </SwiperSlide>

                    <SwiperSlide  >

                        <BlockTitle>Things to be grateful for</BlockTitle>
                        <List inlineLabels noHairlinesMd>
                            <ListInput
                                type="text"
                                value={this.state.gratefulFor1}
                                placeholder="1) Something..."
                                clearButton
                                onChange={this.onGratefulFor1Change.bind(this)}
                            />
                            <ListInput
                                type="text"
                                value={this.state.gratefulFor2}
                                clearButton
                                onChange={this.onGratefulFor2Change.bind(this)}
                                placeholder="2) Something..."
                            />
                            <ListInput
                                type="text"
                                value={this.state.gratefulFor3}
                                clearButton
                                onChange={this.onGratefulFor3Change.bind(this)}
                                placeholder="3) Something..."
                            />

                        </List>
                    </SwiperSlide>


                    <SwiperSlide>Day Review Chart

                    <DayPlot
                            db={this.db}
                            userId={firebase.auth().currentUser.email}
                            date={this.props.day}
                        >

                        </DayPlot>

                    </SwiperSlide>
                    <SwiperSlide>
                        <Block >
                            <Row>
                                <Col width="100" className="center-col">
                                    <FontAwesomeIcon icon={faCalendarCheck} size="6x" />
                                </Col>
                            </Row>

                            <Row >


                                <Col width="100" className="center-col" style={{ fontSize: '5vmin', textAlign: 'center' }}>
                                    <p>You reviewed your day.<br></br>
                                        Fantastic!<br></br>
                                        See you tomorrow.
                                     </p>
                                </Col>


                            </Row>

                            <Row >
                                <Col width="100" className="center-col">
                                    <Button fill onClick={this.done.bind(this)}> Done</Button>

                                </Col>

                            </Row>

                        </Block>


                    </SwiperSlide>

                </Swiper>
            </Page>
        )
    }

    onNotesChange(text: string) {
        let newState: State = {
            notes: text
        }
        this.setState(newState);
    }

    onAchivementsChange(text: string) {
        let newState: State = {
            achievements: text
        }
        this.setState(newState);
    }

    onToDoBetterChange(text: string) {
        let newState: State = {
            toDoBetter: text
        }
        this.setState(newState);
    }

    onGratefulFor1Change(event:Event) {
        let input:any = event.target
        let newState: State = {
            gratefulFor1: input.value
        }
        this.setState(newState);
    }

    onGratefulFor2Change(event:Event) {
        let input:any = event.target
        let newState: State = {
            gratefulFor2: input.value
        }
        this.setState(newState);
    }

    onGratefulFor3Change(event:Event) {
        let input:any = event.target
        let newState: State = {
            gratefulFor3: input.value
        }
        this.setState(newState);
    }


    slideChanged(e: any) {

        this.saveChangesToFirebase();

    }

    saveChangesToFirebase() {
        this.db.collection('user').doc(this.userId).collection("dayReview").doc(this.dateString).set(
            {
                moodValue: this.state.moodValue,
                notes: this.state.notes,
                achievements: this.state.achievements,
                toDoBetter: this.state.toDoBetter,
                gratefulFor1: this.state.gratefulFor1,
                gratefulFor2: this.state.gratefulFor2,
                gratefulFor3: this.state.gratefulFor3,
                date: firebase.firestore.Timestamp.fromDate(this.date)
            }).then(v => {
                // this.infoToast.open();
            });
    }




    done() {
        //TODO Persist data. here?

        this.$f7router.navigate('/main/');
    }

    getUsername() {
        let currentUser = firebase.auth().currentUser;
        if (currentUser) {
            return currentUser.displayName
        }

        return "ERROR NO SIGNED IN USER"
    }
}

export default DailyReview;