import React from "react";
import { Page, Navbar, Block } from "framework7-react";

class HowToUse extends React.Component {

    render() {
        return (

            <Page name="How To Use" >
                <Navbar title="How To Use" backLink={true} />


                <Block>
                    <h1 ><span >Welcome! </span></h1>
                    <p ><span >Calmbead is here to help you with your anxiety. It consists of 2 parts:</span>
                    </p>
                    <ul >
                        <li ><span >The button</span></li>
                        <li ><span >The app</span></li>
                    </ul>
                    <p ><span >They work together to help you:</span></p>
                    <ul >
                        <li ><span >Better understand how you are doing</span></li>
                        <li ><span >Better deal with difficult moments</span></li>
                        <li ><span >Notice more the positive moments</span></li>
                    </ul>
                    <p ><span ></span></p>
                    <p ><span >You use the button to track how you are feeling and in at the end of the day, or when
            you are ready, you can review it.</span></p>
                    <p ><span ></span></p>
                    <p ><span >This is how to use the button:</span></p>
                    <p ><span ></span></p>
                    <p ><span >Anxiety: When you feel anxious press the button once. This is an opportunity for you
            to:</span></p>
                    <ul>
                        <li ><span >Take a breath and recognize the anxiety</span></li>
                        <li ><span >Observe the anxiety without judging it or getting attached to it</span></li>
                        <li ><span >Go back to what you were doing and being aware of it</span></li>
                    </ul>
                    <p ><span ></span></p>
                    <p ><span >Good moment: When you are feeling good press the button twice. This is an opportunity
            to:</span></p>
                    <ul>
                        <li ><span >Take a breath and recognize the good moment</span></li>
                        <li ><span >Observe the good feeling without getting attached to it</span></li>
                        <li ><span >Go back to what you were doing being aware of it</span></li>
                    </ul>
                    <p ><span ></span></p>
                    <p ><span >Intense anxiety: When you feel intense anxiety press the button for at least 3
            seconds. This is an opportunity for you to:</span></p>
                    <ul>
                        <li ><span >Take 3 to 5 deep breaths and recognize the anxiety</span></li>
                        <li ><span >Observe the anxiety without judging it or getting attached to it</span></li>
                        <li ><span >If possible go back to what you were doing being aware of it</span></li>
                    </ul>
                    <p ><span ></span></p>
                    <p ><span >This is how to use the app:</span></p>
                    <p ><span >At the end of the day you can review how it went. In case you forgot to use the
                            button, you can still keep track of how you felt. After marking how you felt, you will be able to see how
            your day was:</span></p>
                    <p ><span ></span></p>
                    <p > <img width="80%" src="img/howto1.png" /></p>
                    <p ><span ></span></p>
                    <p ><span >You&rsquo;ll see moments of:</span></p>
                    <p ><span >anxiety <img width="6%" src="img/cloud-light.svg" /></span></p>
                    <p ><span >Good feelings <img width="6%" src="img/sun.svg" /></span></p>
                    <p ><span >Intense anxiety <img width="6%" src="img/cloud.svg" /></span></p>
                    <p ><span ></span></p>
                    <p ><span >As you can see time and location you will be able to reflect and better understand
            your anxiety.</span></p>
                    <p ><span ></span></p>
                    <p ><span ></span></p>



                </Block>

            </Page>
        );
    }


}

export default HowToUse;