import React from 'react';
import {Page, Navbar, Block, Row, Button, Col } from 'framework7-react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { auth } from 'firebaseui';






class EntryPoint extends React.Component {



    // The component's Local state.
    state = {
        isSignedIn: false // Local signed-in state.
    };

    // Configure FirebaseUI.
    private uiConfig: firebaseui.auth.Config = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        credentialHelper: auth.CredentialHelper.NONE,
        signInSuccessUrl:'/main/',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ], 
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        }

    };

    private unregisterAuthObserver?: firebase.Unsubscribe;




    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        if(firebase.auth().currentUser){
            this.$f7router.navigate('/main/');
        } else {
            this.setState({ isSignedIn: false});

        }

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
                this.setState({ isSignedIn: !!user });
                if(user){
                    this.$f7router.navigate('/main/');
                }
                
                
            }
        );

        // if (window.hasOwnProperty("Android") ) {
        //     let myWindow = window as any;
        //     alert(myWindow.Android.token());
        //     firebase.auth().signInWithCustomToken(myWindow.Android.token());
        // } else {
        //     alert("No Android");
        // }




    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        if (this.unregisterAuthObserver) {
            this.unregisterAuthObserver();
        }


    }

    render() {
        return (
            <Page name="Welcome">

                <Navbar title="Welcome"></Navbar>
                {/* Page content */}
                <Block>
                    <Row><h4 style={{ textAlign: 'center', width: '100%' }}>Welcome to CalmBead</h4></Row>

                </Block>

                {this.loginComponent()}

            </Page>
        );
    }

    loginComponent() {
        if (!this.state.isSignedIn) {
            return (
                <Block>
                    <p style={{ textAlign: 'center', width: '100%' }}>Please sign-in:</p>
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                </Block>
            );
        }
        //this.$f7router.navigate('/main/');
        return (
           <Block>

                <Col width="100" className="center-col">
                    Signing In....
                </Col>
           </Block>

        );
    }



    


}

export default EntryPoint;
