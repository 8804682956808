import React from "react";
import { Page, Navbar, Block, BlockTitle, BlockHeader, TextEditor, Row, Col, Button } from "framework7-react";
import firebase from "firebase";
import { Toast } from "framework7/components/toast/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface State {
    submitted:boolean;
}

interface FeedbackModel{
    userId: string;
    time: firebase.firestore.Timestamp;
    feedbackText: string;
}

class Feedback extends React.Component<any, State, any> {
    private feedbackText: string;
    private db: firebase.firestore.Firestore= firebase.firestore();
    private userId: string = firebase.auth().currentUser.email;
    private toastBottom: Toast.Toast;

    state:State={
        submitted:false
    }


    render() {
        let submitBlock = (<Block>
            <BlockTitle>Feedback</BlockTitle>
            <BlockHeader>Tell us what you think.</BlockHeader>
            <TextEditor
                placeholder="Your feedback..."
                mode="popover"
                buttons={['bold', 'italic', 'underline', 'strikeThrough']}
                style={{ height: "150px" }}
                onTextEditorChange={this.onEditorChange.bind(this)}
            />

            <Row >
                <Col width="100" className="center-col">
                    <Button fill onClick={this.submit.bind(this)}> Submit</Button>

                </Col>

            </Row>

        </Block>);

        let submittedBlock = (
            <Block>
                        <Block >
                            <Row>
                                <Col width="100" className="center-col">
                                    <FontAwesomeIcon icon={faPaperPlane} size="6x" />
                                </Col>
                            </Row>

                            <Row >


                                <Col width="100" className="center-col" style={{ fontSize: '5vmin', textAlign: 'center' }}>
                                    <p>Thanks for your feedback!</p>
                                </Col>


                            </Row>

                            <Row >
                                <Col width="100" className="center-col">
                                    <Button fill onClick={this.back.bind(this)}> Go Back!</Button>

                                </Col>

                            </Row>

                        </Block>

            </Block>
        );

        let blocktoShow = this.state.submitted? submittedBlock: submitBlock;



        return (
            <Page name="Feedback">
                <Navbar title="Feedback" backLink={true} />
                {blocktoShow}
            </Page>
        );
    }

    onEditorChange(text: string) {
        this.feedbackText = text;
    }

    submit(){
        const self=this;

        let newFeedback: FeedbackModel = {
            userId: this.userId,
            time: firebase.firestore.Timestamp.fromDate(new Date()),
            feedbackText: this.feedbackText

        }

        this.db.collection('feedback').add(newFeedback)
        .then(docrRef=>{
            self.setState({
                submitted:true
            })

        })
        .catch(error=>{
            if (!self.toastBottom) {
                self.toastBottom = self.$f7.toast.create({
                  text: 'There was a problem submmitting your feedback.',
                  closeTimeout: 3000,
                });
              }
              self.toastBottom.open();
        });

    }

    back(){
        this.$f7router.back();
    }

}

export default Feedback;