import React from "react";
import firebase, { firestore } from 'firebase';
import * as d3 from "d3";
import haversine from '../Helpers/haversine'
import uuidv1 from 'uuid/v1';

interface PlotDataPoint {
    src: string;
    x: Date;
    y: number;
}

interface DayReview {
    src: string;
    date: Date;
}

const OLD_EVENT_MAPPING: { [eventType: string]: string } = {
    "PRESS": "NEGATIVE",
    "DOUBLE_PRESS": "POSITIVE",
    "HOLD": "VERY_NEGATIV"
}

const ICON_MAPPING: { [eventType: string]: string } = {
    "NEGATIVE": "cloud-light.svg",
    "POSITIVE":"sun.svg",
    "VERY_NEGATIV":"cloud.svg"
}


class WeekCloudPlotter {

    private plotData?: d3.Selection<SVGImageElement, PlotDataPoint, SVGGElement, any>;
    private plot: d3.Selection<SVGGElement, unknown, null, undefined>;
    private borderPath: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    private scaleX: d3.ScaleTime<number, number>;
    private scaleY: d3.ScaleLinear<number, number>;
    private xAxis: d3.Axis<number | Date | { valueOf(): number }>;
    private gX: d3.Selection<SVGGElement, unknown, null, undefined>;
    private margin = { top: 80, right: 20, bottom: 30, left: 30 };
    private width: number;
    private height: number;
    private dataImageSize: number;
    private rootSvg: d3.Selection<any, any, any, any>;
    dayReviewDataG: d3.Selection<SVGImageElement, DayReview, SVGGElement, unknown>;

    constructor(private rootElementID: string) {
        this.rootElementID = rootElementID;
        const rootElement = document.getElementById(rootElementID);
        const w = rootElement.clientWidth;
        const h = rootElement.clientHeight;


        
        this.dataImageSize = h / 12;
        this.margin.top = this.dataImageSize*2;
        this.margin.bottom = this.dataImageSize;
        this.margin.left = this.dataImageSize + 5;

        this.height = h - this.margin.top - this.margin.bottom;

        this.width = w - this.margin.left - this.margin.right;


        this.rootSvg = d3.select(rootElement)
            .append('svg')
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom)
            .style("background-color", "lightskyblue");

        // translate this svg element to leave some margin.
        this.plot = this.rootSvg.append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this.plot.append('image')
            .attr("xlink:href", "img/house.svg")
            .attr("x", -this.dataImageSize)
            .attr("y", this.height - this.dataImageSize)
            .attr("width", this.dataImageSize)
            .attr("height", this.dataImageSize);

        this.drawYAxis();
    }

    drawYAxis() {
        this.scaleY = d3.scaleLinear()
            .domain([0, 100])         // This is the min and the max of the data: 0 to 100 if percentages
            .range([this.height - this.dataImageSize, this.dataImageSize]);

    }

    private drawXAxis(start: Date, end: Date) {
        let endDomain = new Date(start.getTime());
        endDomain.setDate(start.getDate()+1);
        this.scaleX = d3.scaleTime()
            .domain([start, endDomain])
            .range([0, this.width])
            .nice();

        this.xAxis = d3.axisBottom(this.scaleX);
        this.xAxis.ticks(8);

        this.gX = this.plot.append('g')
            .attr("transform", "translate(0," + this.height + ")")
            .call(this.xAxis);

    }

    drawData(data: PlotDataPoint[], dataReview: DayReview[], start: Date, end: Date) {
        const self = this;


        this.drawXAxis(start, end);
        this.plotData = this.plot
            .append('g')
            .selectAll('.myPoint')
            .data(data)
            .enter()
            .append('image')
            .attr("xlink:href", function (d) { return "img/" + d.src })
            .attr("x", function (d) { return self.scaleX(d.x) })
            .attr("y", function (d) { return self.scaleY(d.y) })
            .attr("width", this.dataImageSize)
            .attr("height", this.dataImageSize)
            .style("opacity", .8);

        this.dayReviewDataG = this.plot.append('g')
            .selectAll('.mySmile')
            .data(dataReview)
            .enter()
            .append('image')
            .attr("xlink:href", function (d) { return "img/" + d.src })
            .attr("x", function (d) {
                d.date.setHours(12, 0, 0, 0);
                return (self.scaleX(d.date) - (self.dataImageSize ))
            })
            .attr("y", function (d) { return -(self.dataImageSize * 2) })
            .attr("width", this.dataImageSize * 2)
            .attr("height", this.dataImageSize * 2);


        this.rootSvg.call(
            d3.zoom()
                .scaleExtent([(1 / 7), 7])
                .translateExtent([[this.scaleX(start), 0], [this.scaleX(end), 0]])
                .on("zoom", () => {
                    let transform = d3.event.transform;
                    let newXScale = transform.rescaleX(self.scaleX);
                    self.dayReviewDataG.attr("x", function (d) {
                        d.date.setHours(12, 0, 0, 0);
                        return newXScale(d.date) - (self.dataImageSize * 2.5)
                    });
                    self.plotData.attr("x", function (d) { return newXScale(d.x) });
                    self.gX.call(self.xAxis.scale(newXScale));
                }));
    }

    clearData() {
        if (this.plotData) {
            this.plotData.remove();
        }
        if (this.gX) {
            this.gX.remove();
        }
    }

    moveNext() {
        if (this.borderPath && this.plotData) {
            let node = this.borderPath.node();
            if (node) {
                let box = node.getBBox();
                if (box) {
                    let width = box.width;
                    this.plotData.transition().duration(5000)
                        .attr("transform", "translate(-" + width + ")");
                }
            }

        }
    }
}




interface WeekReportProps {
    db: firebase.firestore.Firestore;
    userId: string;
    start: Date;
    end: Date;
}


class WeekReportPlot extends React.Component<WeekReportProps>{

    private weekcloud: WeekCloudPlotter;
    private db: firebase.firestore.Firestore;
    private userId: string;
    private uuid: string;
    private homeLocation: firestore.GeoPoint;
    private startDate: Date;
    private endDate: Date;

    constructor(props: WeekReportProps) {
        super(props);
        this.db = props.db;
        this.userId = props.userId;
        this.uuid = uuidv1();
        this.startDate = props.start;
        this.startDate.setHours(0, 0, 0, 0);
        this.endDate = props.end;
        this.endDate.setHours(23, 59, 59, 999);



    }


    render() {
        return (
            <div className="plot-wrapper">
                <div className="plot" id={"plot-" + this.uuid}>

                </div>
            </div>
        );
    }



    componentDidMount() {
        this.weekcloud = new WeekCloudPlotter("plot-" + this.uuid);
        this.setDate();
    }



    private setDate() {
        this.db.collection("user").doc(this.userId)
            .get()
            .then(docSnap => {
                let data = docSnap.data();
                if (data && data.homeLocation) {
                    this.homeLocation = docSnap.data().homeLocation as firestore.GeoPoint;
                }


            }).finally(() => {
                this.getButtonEvents();
            })

    }

    private async getButtonEvents() {
        const self = this;





        let startTimestamp = firebase.firestore.Timestamp.fromDate(this.startDate);
        let endTimestamp = firebase.firestore.Timestamp.fromDate(this.endDate);

        try {

        }
        catch (error) {
            console.log(error.message);
        }
        let buttonEventsQuerySnapshot = await this.db.collection("user").doc(this.userId)
            .collection("events")
            .where("time", ">", startTimestamp)
            .where("time", "<", endTimestamp).get();

        let dayReviewQuerySnapshot = await this.db.collection("user").doc(this.userId)
            .collection("dayReview")
            .where("date", ">", startTimestamp)
            .where("date", "<", endTimestamp).get();



        let buttonEvents = buttonEventsQuerySnapshot.docs.map(docSnap => docSnap.data());
        if (buttonEvents.length === 0) {
            self.weekcloud.clearData();
            return;
        }

        let reviewDocuments = dayReviewQuerySnapshot.docs.map(docSnap => docSnap.data());

        self.drawData(buttonEvents,reviewDocuments, self.startDate, self.endDate);

    }

    getMoodSvg(value:number){
        let result = "sad-tear.svg";
        if (value > 80) {
            result = "smile-beam.svg";
        } else if (value > 60) {
            result = "smile.svg";
        } else if (value > 40) {
            result = "meh.svg";
        } else if (value > 20) {
            result = "frown.svg";
        }
        return result
    }


    private drawData(buttonEvents: firestore.DocumentData[],reviewDocuments: firestore.DocumentData[], startDate: Date, endDate: Date) {
        const self = this;
        let maxDistance: number;
        if (self.homeLocation) {
            //Compute distance to home for each event
            buttonEvents.forEach(buttonEvent => {
                buttonEvent.homeDistance = haversine(self.homeLocation, buttonEvent.location, { format: "firestore", unit: "meter" });
            });
            maxDistance = buttonEvents.map(e => e.homeDistance).reduce((a, b) => Math.max(a, b));
        }
        let daycloudData = buttonEvents.map(buttonEvent => {

            let eventTimestamp = buttonEvent.time as firestore.Timestamp;

            let yValue = 50;

            if (self.homeLocation) {
                if (maxDistance === 0) {
                    yValue = 0;
                } else {
                    yValue = (buttonEvent.homeDistance / maxDistance) * 100;

                }

            }
            let emotion = buttonEvent.mappedEmotion? buttonEvent.mappedEmotion: OLD_EVENT_MAPPING[buttonEvent.eventType];

            let image = ICON_MAPPING[emotion];

            return { src: image, x: eventTimestamp.toDate(), y: yValue };

        });

        let reviewData = reviewDocuments.map(r =>({ src: this.getMoodSvg(r.moodValue) , date: r.date.toDate() }));
        self.weekcloud.clearData();
        self.weekcloud.drawData(daycloudData, reviewData, startDate, endDate)

    }



}

export default WeekReportPlot;