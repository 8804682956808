import React from 'react';
import './App.css';
import { App, View, Panel, Page, Block, BlockTitle, List, ListItem} from 'framework7-react';
import { Framework7Params } from 'framework7/components/app/app-class';
import firebase from 'firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSignOutAlt, faQuestionCircle, faCogs, faPaperPlane, faSync} from '@fortawesome/free-solid-svg-icons'

import routes from './routes';

const f7params: Framework7Params = {
  theme: 'ios',
  name: 'TalismApp',
  id: "com.talismapp.test",
  routes:routes
} as Framework7Params;

const firebaseConfig = {
  apiKey: "AIzaSyASo52BHm79PZtfXba5_FDGs9XmMJ0CRnM",
  authDomain: "talisman-test-89c16.firebaseapp.com",
  databaseURL: "https://talisman-test-89c16.firebaseio.com",
  projectId: "talisman-test-89c16",
  storageBucket: "talisman-test-89c16.appspot.com",
  messagingSenderId: "432208162648",
  appId: "1:432208162648:web:054d53935c92dfa639bf2d",
  
};
firebase.initializeApp(firebaseConfig);







class TalismApp extends React.Component {
  render(){
    return (
      <App params={f7params} colorTheme="black" >
  {/* Left resizable Panel with Reveal effect */}
    <Panel left cover>
      <View>
        <Page>
          <Block>

          <BlockTitle> <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon> Settings</BlockTitle>
          <List>
            <ListItem  title="Home Location" onClick={this.setHomeLocation.bind(this)} >
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
            </ListItem>
            <ListItem  onClick={(e) =>this.logout(e)} title="Sign Out">
              <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
            </ListItem>
            <ListItem  onClick={(e) =>{
              this.$f7.views.main.router.navigate('/howtouse/');
              this.$f7.panel.close("left");
              }} title="How To Use">
              <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
            </ListItem>
            <ListItem  onClick={(e) =>{
              this.$f7.views.main.router.navigate('/feedback/');
              this.$f7.panel.close("left");
              }} title="Send Feedback">
              <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon>
            </ListItem>
            <ListItem  onClick={(e) =>{
              window.location.reload(true);

              }} title="Update">
              <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
            </ListItem>



          </List>

          </Block>
        </Page>
      </View>
    </Panel>

      {/* Current View/Router, initial page will be loaded from home.jsx component */}
      <View main url="/" />
    </App>
    )
  }




  

  logout(e: React.MouseEvent): void {
    firebase.auth().signOut();
    this.$f7.views.main.router.navigate('/');
    this.$f7.panel.close("left");
  }


  setHomeLocation(event: React.MouseEvent) {

    //this.locationErrorToast.open();
    if ("geolocation" in navigator) {
        /* geolocation is available */
        //self.infoToast.open();
        navigator.geolocation.getCurrentPosition(position => {

            this.onLocationReady(position);
        },
            error => {
                // this.locationErrorToast.open();
                alert(error.message);
            },
            { enableHighAccuracy: true, maximumAge: 10000 }
        );

    } else {

        // this.locationErrorToast.open();
    }
}

onLocationReady(position: Position) {
    const db = firebase.firestore();
    const userId = firebase.auth().currentUser.email;
    db.collection('user').doc(userId).set(
        {
            homeLocation: new firebase.firestore.GeoPoint(position.coords.latitude, position.coords.longitude)
        }).then(v => {
            // this.infoToast.open();
        });
}



}


export default TalismApp;
