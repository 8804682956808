import React from "react";
import firebase, { firestore } from 'firebase';
import * as d3 from "d3";
import haversine from '../Helpers/haversine'
import uuidv1 from 'uuid/v1';
import { timeFormat } from "d3";

interface PlotDataPoint {
    src: string;
    x: Date;
    y: number;
}


const EVENT_MAPPING: { [eventType: string]: string } = {
    "PRESS": "POSITIVE",
    "DOUBLE_PRESS": "NEGATIVE",
    "HOLD": "VERY_NEGATIV"
}

const OLD_EVENT_MAPPING: { [eventType: string]: string } = {
    "PRESS": "NEGATIVE",
    "DOUBLE_PRESS": "POSITIVE",
    "HOLD": "VERY_NEGATIV"
}

const ICON_MAPPING: { [eventType: string]: string } = {
    "NEGATIVE": "cloud-light.svg",
    "POSITIVE":"sun.svg",
    "VERY_NEGATIV":"cloud.svg"
}



class DayCloudPlotter {

    private plotData?: d3.Selection<SVGImageElement, PlotDataPoint, SVGGElement, any>;
    private rootSvg: d3.Selection<SVGGElement, unknown, null, undefined>;
    private borderPath: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    private scaleX: d3.ScaleTime<number, number>;
    private scaleY:d3.ScaleLinear<number, number>;
    private xAxis: d3.Axis<number|Date|{valueOf():number}>;
    private xAxisElement: d3.Selection<SVGGElement, unknown, null, undefined>;
    private margin = { top: 20, right: 20, bottom: 30, left: 30 };
    private width : number;
    private height: number;
    private dataImageSize: number;

    constructor(private rootElementID: string) {
        this.rootElementID = rootElementID;
        const rootElement = document.getElementById(rootElementID);
        const w = rootElement.clientWidth;
        const h = rootElement.clientHeight;


        this.height = h - this.margin.top - this.margin.bottom;
        this.dataImageSize = this.height/15;
        this.margin.left= this.dataImageSize+5;

        this.width = w - this.margin.left - this.margin.right;


        this.rootSvg = d3.select(rootElement)
            .append('svg')
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom)
            .style("background-color", "lightskyblue")
            // translate this svg element to leave some margin.
            .append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

            this.rootSvg.append('image')
            .attr("xlink:href", "img/house.svg")
            .attr("x", -this.dataImageSize)
            .attr("y", this.height-this.dataImageSize)
            .attr("width", this.dataImageSize)
            .attr("height", this.dataImageSize);

        this.drawYAxis();
    }

    drawYAxis(){
        this.scaleY = d3.scaleLinear()
            .domain([0, 100])         // This is the min and the max of the data: 0 to 100 if percentages
            .range([this.height-this.dataImageSize, this.dataImageSize]);

        // let yAxis = d3.axisLeft(this.scaleY);
        // yAxis.ticks(0);

        // this.rootSvg.append('g').call(yAxis);



    }

    private drawXAxis(start: Date, end: Date) {
        this.scaleX = d3.scaleTime()
            .domain([start, end])
            .range([0, this.width])
            .nice();

        this.xAxis = d3.axisBottom(this.scaleX);
        this.xAxis.ticks(d3.timeHour.every(4));

        this.xAxisElement = this.rootSvg.append('g')
        .attr("transform", "translate(0," + this.height + ")")
        .call(this.xAxis);

    }

    drawData(data: PlotDataPoint[], start: Date, end: Date) {
        const self = this;


        this.drawXAxis(start, end);
        this.plotData = this.rootSvg
            // .call(d3.zoom().on("zoom", () => {
            //     this.plotData.attr("transform", d3.event.transform)
            // }))
            .append('g')
            .selectAll('.myPoint')
            .data(data)
            .enter()
            .append('image')
            .attr("xlink:href", function (d) { return "img/" + d.src })
            .attr("x", function (d) { return self.scaleX(d.x)})
            .attr("y", function (d) { return self.scaleY(d.y)})
            .attr("width", this.dataImageSize)
            .attr("height", this.dataImageSize)
            .style("opacity", .8);
    }

    clearData() {
        if (this.plotData) {
            this.plotData.remove();
        }
        if(this.xAxisElement){
            this.xAxisElement.remove();
        }
    }

    moveNext() {
        if (this.borderPath && this.plotData) {
            let node = this.borderPath.node();
            if (node) {
                let box = node.getBBox();
                if (box) {
                    let width = box.width;
                    this.plotData.transition().duration(5000)
                        .attr("transform", "translate(-" + width + ")");
                }
            }

        }
    }
}



interface DayplotProps {
    db: firebase.firestore.Firestore;
    userId: string;
    date: Date;
}
class DayPlot extends React.Component<DayplotProps>{

    private daycloud: DayCloudPlotter;
    private db: firebase.firestore.Firestore;
    private userId: string;
    private uuid: string;
    private homeLocation: firestore.GeoPoint;
    private startDate: Date;

    constructor(props: DayplotProps) {
        super(props);
        this.db = props.db;
        this.userId = props.userId;
        this.uuid = uuidv1();
        this.startDate = props.date;
        this.startDate.setHours(0,0,0,0);



    }


    render() {
        return (
            <div className="plot-wrapper">
                <div className="plot" id={"plot-" + this.uuid}>

                </div>
            </div>
        );
    }



    componentDidMount() {
        this.daycloud = new DayCloudPlotter("plot-" + this.uuid);
        this.setDate();
    }



    private setDate() {
        this.db.collection("user").doc(this.userId)
            .get()
            .then(docSnap => {
                let data = docSnap.data();
                if(data && data.homeLocation){
                    this.homeLocation = docSnap.data().homeLocation as firestore.GeoPoint;
                }

                
            }).finally(() => {
                this.getButtonEvents();
            })

    }

    private getButtonEvents() {
        const self = this;
        let endDate = new Date(this.startDate.getTime());
        endDate.setHours(23, 59, 59, 999);
        console.log("Start Date: "+ this.startDate.toISOString());
        console.log("End Date: "+ endDate.toISOString());



        let startTimestamp = firebase.firestore.Timestamp.fromDate(this.startDate);
        let endTimestamp = firebase.firestore.Timestamp.fromDate(endDate);


        this.db.collection("user").doc(this.userId)
            .collection("events")
            .where("time", ">", startTimestamp)
            .where("time", "<", endTimestamp)
            .onSnapshot(function (querySnapshot) {

                let buttonEvents = querySnapshot.docs.map(docSnap => docSnap.data());
                if (buttonEvents.length === 0) {
                    self.daycloud.clearData();
                    return;
                }

                self.drawData(buttonEvents, self.startDate, endDate);



            },
                error => {
                    console.log(error.message);
                }
            );

    }

    


    private drawData(buttonEvents: firestore.DocumentData[], startDate:Date , endDate:Date) {
        const self = this;
        let maxDistance: number;
        if (self.homeLocation) {
            //Compute distance to home for each event
            buttonEvents.forEach(buttonEvent => {
                buttonEvent.homeDistance = haversine(self.homeLocation, buttonEvent.location, { format: "firestore", unit: "meter" });
            });
            maxDistance = buttonEvents.map(e => e.homeDistance).reduce((a, b) => Math.max(a, b));
        }
        let daycloudData = buttonEvents.map(buttonEvent => {

            let eventTimestamp = buttonEvent.time as firestore.Timestamp;

            let yValue = 50;

            if (self.homeLocation) {
                if (maxDistance === 0) {
                    yValue = 0;
                } else {
                    yValue = (buttonEvent.homeDistance / maxDistance) * 100;

                }

            }

            let emotion = buttonEvent.mappedEmotion? buttonEvent.mappedEmotion: OLD_EVENT_MAPPING[buttonEvent.eventType];

            let image = ICON_MAPPING[emotion];


            return { src: image, x: eventTimestamp.toDate(), y: yValue };

        });
        self.daycloud.clearData();
        self.daycloud.drawData(daycloudData, startDate, endDate)

    }

}

export default DayPlot


