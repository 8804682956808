import { Router } from "framework7/modules/router/router";
import DailyReview from "./Pages/DailyReview";
import EntryPoint from "./Pages/EntryPoint";
import MainPage from './Pages/MainPage';
import HowToUse from './Pages/HowToUse';
import Feedback from "./Pages/Feedback";
import WeekReport from "./Pages/WeekReport";



const routes: Router.RouteParameters[] = [
    {
        name: 'Login',
        path:'/',
        component:EntryPoint
    },
    {
        name:'Enter',
        path:'/main/',
        component:MainPage

    },
    {
        name:'Daily Review',
        path:'/daily/',
        component: DailyReview
    },
    {
        name:'How To Use',
        path:'/howtouse/',
        component: HowToUse
    }, 
    {
        name:'Feedback',
        path:'/feedback/',
        component: Feedback
    },
    {
        name:"Week Report",
        path:'/week/',
        component: WeekReport
    }
]

export default routes;