import React from 'react';
import { Page, Navbar, Block, Row, Button, Col, Link, NavRight, Icon, Toggle } from 'framework7-react';
import firebase from 'firebase';
import { Toast } from 'framework7/components/toast/toast';
import uuidv1 from 'uuid/v1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Calendar } from 'framework7/components/calendar/calendar';
import {DailyReviewProps} from './DailyReview';
import { WeekReportProps } from './WeekReport';


type CalendarType = "Day"| "Week";

interface State {
    calendarType: CalendarType;  
}


class MainPage extends React.Component<any,State, any> {



    private locationErrorToast?: Toast.Toast;
    private infoToast?: Toast.Toast;
    private calendarElementUuid: string;
    private calendarInline: Calendar.Calendar;
    
    state :State = {
        calendarType:"Day"
    }


    constructor(props: any) {
        super(props);
        this.calendarElementUuid = uuidv1();

    }


    componentDidMount() {
        this.locationErrorToast = this.$f7.toast.create({
            text: "Sorry! We couldn't get your location :(",
            closeTimeout: 2000,
        });

        this.infoToast = this.$f7.toast.create({
            text: "Yeah! :)",
            closeTimeout: 2000,
        });

    }

    componentWillUnmount() {
        if (this.locationErrorToast) {
            this.locationErrorToast.destroy();
        }
    }


    render() {
        return (
            <Page name="TalisMapp" onPageInit={this.onPageInit.bind(this)} onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}>


                <Navbar title="CalmBead" >
                    <NavRight>
                        <FontAwesomeIcon icon={faBars} onClick={this.onMenuClick.bind(this)} />
                    </NavRight>
                </Navbar>
                <Block>
                    <Block>
                        <Row >

                            <Col width="100" className="center-col">
                                <Button outline
                                    style={{
                                        height: '20vh',
                                        width: '20vh',
                                        borderRadius: '100%',
                                        textAlign: 'center',
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }}
                                     onClick={this.reviewDay.bind(this)}>Review <br/> Today</Button>
                            </Col>
                        </Row>
                    </Block>

                    <Block>
                        <Col width="100" className="center-col">
                            <span>Day</span>
                            <Toggle color="black" onToggleChange={this.dayWeekToggleChange.bind(this)} />
                            <span>Week</span>
                        </Col>

                        <Col width="100" className="center-col">
                            <div id={this.calendarElementUuid} className="calendar-inline-container"></div>
                        </Col>

                        <Col width="100" className="center-col">
                            <Button  style={{"visibility": (this.state.calendarType ==="Day" ? "hidden": "visible")}} >Review Week</Button>
                        </Col>
                    </Block>

                </Block>


            </Page>
        )
    }

    reviewDay(){
        let today = new Date();
        today.setHours(0,0,0,0);
        let props: DailyReviewProps = {
            day: today
        }

        this.$f7router.navigate('/daily/',{props: props});
    }

    dayWeekToggleChange(checked?: boolean){
        if(checked){
            this.calendarInline.params.rangePicker=true;
            this.setState({calendarType:"Week"});

        } else {
            this.calendarInline.params.rangePicker=false;
            this.setState({calendarType:"Day"});
        }
    }



    onPageInit(e: any) {
        const self = this;
        const app = self.$f7;
        const $ = self.$$;

        // Inline with custom toolbar
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        self.calendarInline = app.calendar.create({
            containerEl: '#' + self.calendarElementUuid,
            rangePicker:self.state.calendarType==="Week",

            value: [new Date()],
            renderToolbar() {
                return `
          <div class="toolbar calendar-custom-toolbar no-shadow">
            <div class="toolbar-inner">
              <div class="left">
                <a href="#" class="link icon-only"><i class="icon icon-back"></i></a>
              </div>
              <div class="center"></div> 
              <div class="right">
                <a href="#" class="link icon-only"><i class="icon icon-forward"></i></a>
              </div>
            </div>
          </div>
        `.trim();
            },

            on: {
                init(c) {


                    $('.calendar-custom-toolbar .center').text(`${monthNames[c.currentMonth]}, ${c.currentYear}`);
                    $('.calendar-custom-toolbar .left .link').on('click', () => {
                        self.calendarInline.prevMonth(0)
                    });
                    $('.calendar-custom-toolbar .right .link').on('click', () => {
                        self.calendarInline.nextMonth(0);
                    });
                },
                monthYearChangeStart(c) {
                    $('.calendar-custom-toolbar .center').text(`${monthNames[c.currentMonth]}, ${c.currentYear}`);
                },

                dayClick(cal, el, year, month, date) {

                    if(self.state.calendarType==="Week"){
                        let value = new Date(year, month, date);
                        console.warn("Setting Range starting "+value.toDateString());
    
                        const initialDate = self.getPreviousMonday(value);
    
    
                        const newValue: Date[] = [];
                        for (let i = 0; i < 7; i++) {
                            let nextDay = new Date(initialDate.getTime());
                            nextDay.setDate(nextDay.getDate() + i);
                            newValue.push(nextDay)
    
                        }
                        
    
                        cal.setValue(newValue);
                        cal.update();

                        let props: WeekReportProps = {
                            start: newValue[0],
                            end:newValue[6]
                        }
                        self.$f7router.navigate('/week/',{props: props});

                        //console.warn("Setting Range "+ JSON.stringify(cal.getValue()));
                    } else {
                        let props: DailyReviewProps = {
                            day: new Date(year,month, date,12,0,0)
                        }
                        self.$f7router.navigate('/daily/',{props: props});
                    }                    


                }
            },
        });

    }

    getPreviousMonday(date: Date) :Date{

        let day = date.getDay();

        if (day === 1) {
           return date;
        }
        else {
            let prevMonday = new Date(date.getTime());
            prevMonday.setDate(date.getDate() - day+1);
            return prevMonday;
        }
    }

    onMenuClick(e: React.MouseEvent) {
        this.$f7.panel.open("left");
    };

    onPageBeforeRemove() {
        const self = this;
        self.calendarInline.destroy();
    }




}

export default MainPage;